import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import { getUpgradeLink } from '../constants/urls';

export default class LowCostBannerWrapper extends Component {
	yellowZone = 50;

	@service currentUser;

	constructor() {
		super(...arguments);
	}

	get percentUsed() {
		return parseInt((this.args.count / this.args.quota) * 100);
	}

	get title() {
		// if title is explicitly passed use it, otherwise infer it based on the feature
		if (this.args.title !== undefined) return this.args.title;

		switch (this.args.feature) {
			case 'recommended_responses':
				return ''; // technically has no title
			case 'automation_templates':
				return 'PORTAL BY CASE STATUS';
			default:
				return 'PORTAL BY CASE STATUS';
		}
	}

	get selectedCasesText() {
		return this.args.selectedCasesText;
	}

	get subDescription() {
		if (this.args.feature == 'recommended_responses') {
			const proportion = this.quotaExceeded
				? 'All AI recommended responses used'
				: `${this.percentUsed}% of AI recommended responses used.`;
			return [proportion, 'Upgrade to increase your AI quota.'];
		} else if (this.args.feature == 'automation_templates') {
			const proportion = this.quotaExceeded
				? `All (${this.args.quota})`
				: `${this.args.count} of ${this.args.quota}`;
			return [
				`${proportion} automations in use.`,
				'Upgrade to get more automations.',
			];
		} else if (this.args.feature == 'bulk_message') {
			return [
				`${this.quotaAndCountDifference} bulk messages remaining this month.`,
				`Your firm has ${this.args.quota} bulk messages available to send each month.`,
			];
		} else if (this.args.feature == 'bulk_case_activation') {
			return [
				`${this.args.count} of ${this.args.quota} cases activated.`,
				`Cases are activated when the first message is sent. If any of the selected ${this.selectedCasesText} are inactive, sending this message will activate them. Close or remove existing cases to activate more, or upgrade your plan to activate additional cases.`,
			];
		} else if (this.args.feature == 'single_case_activation') {
			const proportion = this.quotaExceeded
				? `All ${this.args.quota}`
				: `${this.args.count} of ${this.args.quota}`;
			return [
				`${proportion} cases activated.`,
				'Close or remove existing cases to activate more, or upgrade your plan to activate additional cases.',
			];
		} else {
			return 'SubDescription not configured yet';
		}
	}

	get subDescriptionIsString() {
		return typeof this.subDescription === 'string';
	}

	get quotaExceeded() {
		return this.args.count >= this.args.quota;
	}

	get quotaAndCountDifference() {
		// clamp to 0 so we don't show negative remaining
		return Math.max(0, this.args.quota - this.args.count);
	}

	get circleColor() {
		if (this.percentUsed >= 100) {
			return 'red';
		} else if (this.percentUsed == 0) {
			return '#CED1D6';
		} else if (this.percentUsed >= this.yellowZone) {
			return '#419ef8';
		} else {
			return '#419ef8';
		}
	}

	get color() {
		if (this.args.color) {
			return this.args.color;
		} else if (this.percentUsed >= 100) {
			return 'red';
		} else if (this.percentUsed >= this.yellowZone) {
			return 'yellow';
		} else {
			return 'green';
		}
	}

	get upgradeUrl() {
		if (this.args.disableLink) return null;
		return getUpgradeLink(this.currentUser.user.firm.get('firmSettings.firmSettings'));
	}
}