/** @format */

import { inject as service } from '@ember/service';
import Route from '@ember/routing/route';
import ENV from 'case-status/config/environment';
import { hash } from 'rsvp';
import CompanySettingsRouteMixin from '../../../../mixins/route/settings/company';

export default Route.extend(CompanySettingsRouteMixin, {
	store: service(),
	currentUser: service(),
	session: service(),
	ajax: service(),

	beforeModel() {
		this._super(...arguments);
		const authenticatedUserType = this.get(
			'session.data.authenticated.user_type',
		);

		switch (authenticatedUserType) {
			case 'client':
				this.replaceWith('app.client.cases');
				break;
			case 'member':
				this.replaceWith('app.organization.settings.organization');
				break;
		}

		this.send('gatekeeper', 'settings_firm', 'settings.users');

		return this.get('currentUser.user')
			.reload();
	},
	model() {
		return hash({
			firm: this.store.findRecord('firm', this.get('currentUser.user.firm.id')),
		});
	},

	setupController(controller) {
		this._super(...arguments);

		this.store
			.query('action', {
				actionType: 'outside_firm_hours',
			})
			.then((response) => {
				const action = response.get('firstObject');

				if (action) {
					controller.set('enabledOutsideHoursAutomation', true);
					controller.set('hoursAutomation', action);
				} else {
					controller.set('hoursAutomation', null);
				}
			})
			.catch(() => {
				controller.set('hoursAutomation', null);
			});
	},
});
