/** @format */
/* eslint ember/no-classic-classes: 0 */
/* eslint ember/no-actions-hash:  0 */

import { all } from 'rsvp';
import { inject as service } from '@ember/service';
import Route from '@ember/routing/route';

export default Route.extend({
	store: service(),
	session: service(),
	ajax: service(),

	beforeModel() {
		this.store.unloadAll('case');
	},

	model: function () {
		//* Set cache-buster since this is new data being called;
		this.set('ajax.noCache', true);

		const cases = this.store.findAll('case');

		this.checkCases(cases);

		return cases;
	},

	afterModel(cases) {
		return all(cases.invoke('get', 'caseType'));
	},

	checkCases(cases, omittedChecks = {}) {
		if (cases) {
			//* Check Case Count
			if (!omittedChecks.count) {
				this.checkCasesCount(cases);
			}
		}
	},

	checkCasesCount(cases) {
		if (cases) {
			cases.then((caseList) => {
				const caseArray = caseList.toArray() || caseList; //* Falling back to the original caseList in case for some reason this method is passed a non Ember-esque Array.
				const caseArrayLength = caseArray.length;

				//* What to do if the case count is exactly 1
				if (caseArrayLength === 1) {
					const caseId = caseArray[0].id;

					if (caseId & !this.didGoToCase) {
						this.set('didGoToCase', true);
						this.transitionToCase(caseId);
					}
				}
			});
		}
	},

	transitionToCase(id) {
		if (id) {
			this.transitionTo(`app.client.case.info`, id);
		}
	},
});
