/** @format */
/* eslint ember/no-classic-classes: 0 */
/* eslint ember/no-actions-hash:  0 */


import { inject as service } from '@ember/service';
import Controller from '@ember/controller';
import { computed, observer } from '@ember/object';
import { debounce } from '@ember/runloop';
import ENV from 'case-status/config/environment';
import { reject } from 'rsvp';

export default Controller.extend({
	company: service(),
	ajax: service(),
	notifications: service(),
	currentUser: service(),
	mobile: service(),
	caseIdSearchTerm: '',
	isLoading: false,

	hasCases: computed('model', 'model.length', function () {
		if (!this.model) false;
		if (this.model.length > 0) return true;
		return false;
	}),

	activeCases: computed('model', 'hasCases', function () {
		if (this.hasCases) {
			const activeCases = [...this.model.toArray()].filter((theCase) => {
				return theCase.activatedDate;
			});
			return activeCases;
		} else return [];
	}),

	inactiveCases: computed('model', 'hasCases', function () {
		if (this.hasCases) {
			const inactiveCases = [...this.model.toArray()].filter((theCase) => {
				return !theCase.activatedDate;
			});
			return inactiveCases;
		} else return [];
	}),

	onCaseIdSearchTermUpdate: observer('caseIdSearchTerm', function () {
		this.set('isLoading', true);
		debounce(this, this.searchCases, 500);
	}),

	queryParams: computed('caseIdSearchTerm', function () {
		const queryParams = {
			filter: {
			},
			page: {},
		};

		if (this.caseIdSearchTerm) {
			queryParams.filter.readable_id = this.caseIdSearchTerm;
		}

		return queryParams;
	}),

	searchCases() {
		this.set('isLoading', true);

		this.set('ajax.noCache', true);
		this.store.query('case', this.queryParams)
			.then((cases) => {
				const caseArray = cases.toArray();
				this.set('model', caseArray);
			})
			.finally(() => {
				this.set('isLoading', false);
			});
	},

	actions: {
		requestAccess() {
			if (this.isRequestingAccess || this.requestedAccess) return reject();
			this.set('isRequestingAccess', true);
			return this.ajax
				.post(
					`${ENV.host}/chat_messages/${this.currentUser.user.get(
						'firm.id',
					)}/client_waiting`,
				)
				.then(() => {
					this.set('requestedAccess', true);
				})
				.catch(() => {
					this.notifications.error(
						'An error has occurred while request access, please refresh the page and try again.',
						{
							canClose: true,
							autoClear: true,
							clearDuration: 5000,
						},
					);
				})
				.finally(() => {
					this.set('isRequestingAccess', false);
				});
		},
	},
});
